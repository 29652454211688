// @ts-ignore
import Ready from "@/utils/ready";
import tippy from 'tippy.js';

const selector = '[data-popover]';

Ready.watch(selector, (element: HTMLElement) => {
  const id = element.dataset.popover;
  const content = document.querySelector(`[data-popover-content="${id}"]`);
  if (content) {
    tippy(element, {
      content,
      duration: 0,
      arrow: false,
      allowHTML: true,
    });
  }
});
