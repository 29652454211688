// @ts-ignore
import Swiper from 'swiper/bundle';
// import Ready from '@/utils/ready';
// @ts-ignore
import Ready from "@/utils/ready";
// import 'swiper/css';

const selector = '[data-ref="slider"]';

Ready.watch(selector, (element: HTMLElement) => {
  const option = element.dataset.option ? JSON.parse((element.dataset.option as any)) : {};
  const buttonNext: any = element.querySelector(`[data-ref="slider-button-next"]`);
  const buttonPrev: any = element.querySelector(`[data-ref="slider-button-prev"]`);
  return new Swiper(element, {
    navigation: buttonNext && buttonPrev ? {
        nextEl: `[data-ref="slider-button-next"][data-id="${ buttonNext.dataset.id }"]`,
        prevEl: `[data-ref="slider-button-prev"][data-id="${ buttonPrev.dataset.id }"]`,
      }
      : false,
    scrollbar: {
      el: '.swiper-scrollbar',
    },
    threshold: 10,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    ...option,
  });
});
