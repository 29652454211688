import Vue from 'vue';
import * as _ from 'lodash';
// @ts-ignore
import QRCode from 'qrcode';

// import '@/js/detect-ie';
import '@/js/assets';
import '@/js/header';
import '@/js/navigation';
import '@/js/imageSlider';
import '@/js/slider';
import '@/js/choice';
import '@/js/form-validation';
import '@/js/imageLightbox';
import '@/js/homeSlider';
import '@/js/object-fit';
import '@/js/clampify';
import '@/js/accordion';
import '@/js/productFilter';
import '@/js/header-search';
// import '@/js/solutionFilter';
import '@/js/market-lines';
import '@/js/alert';
import '@/js/cookie';
import '@/js/filepond';
import '@/js/map';
import '@/js/redirect-popin';
import '@/js/popover';

// @ts-ignore
import Alpine from 'alpinejs';

(window as any).Alpine = Alpine;
Alpine.start();

(window as any).lodash = _;

const ElementQueries = require('css-element-queries/src/ElementQueries');
ElementQueries.listen();
ElementQueries.init();

(window as any).QRCode = QRCode;

Vue.config.productionTip = false;

// new Vue({
//   render: h => h(App)
// }).$mount("#app");
